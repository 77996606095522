/*!
 * Start Bootstrap - Landing Page v5.0.4 (https://startbootstrap.com/template-overviews/landing-page)
 * Copyright 2013-2019 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-landing-page/blob/master/LICENSE)
 */

 body {
  font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  background-color: #F7F7F7;
}


.table td, .table th{
  text-align: center;   
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}


h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
}

header.masthead {
  position: relative;
  background-color: #343a40;
  
  background-size: cover;
  padding-top: 8rem;
  padding-bottom: 8rem;
}

header.masthead .overlay {
  position: absolute;
  background-color: #212529;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.3;
}

header.masthead h1 {
  font-size: 2rem;
}

@media (min-width: 768px) {
  header.masthead {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
  header.masthead h1 {
    font-size: 3rem;
  }
}

.showcase .showcase-text {
  padding: 3rem;
}

.showcase .showcase-img {
  min-height: 30rem;
  background-size: cover;
}

@media (min-width: 768px) {
  .showcase .showcase-text {
    padding: 7rem;
  }
}

.features-icons {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.features-icons .features-icons-item {
  max-width: 20rem;
}

.features-icons .features-icons-item .features-icons-icon {
  height: 7rem;
}

.features-icons .features-icons-item .features-icons-icon i {
  font-size: 4.5rem;
}

.features-icons .features-icons-item:hover .features-icons-icon i {
  font-size: 5rem;
}

.testimonials {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.testimonials .testimonial-item {
  max-width: 18rem;
}

.testimonials .testimonial-item img {
  max-width: 12rem;
  -webkit-box-shadow: 0px 5px 5px 0px #adb5bd;
  box-shadow: 0px 5px 5px 0px #adb5bd;
}

.call-to-action {
  position: relative;
  background-color: #343a40;
  background-size: cover;
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.call-to-action .overlay {
  position: absolute;
  background-color: #212529;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.3;
}

footer.footer {
  padding-top: 4rem;
  padding-bottom: 4rem;
}


.scrolled {
  background-color: rgb(238, 238, 238, 0.80)!important; /* IE */
}


ul, ol{
  padding-left: 0px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiAutocomplete-input,
.MuiAutocomplete-inputRoot {
  cursor: pointer !important;
}

.MuiTableCell-paddingNone {
  width: 10px;
}

.MuiButtonBase-root {
  outline: none !important;
}

div.MuiListItemText-root {
  white-space: break-spaces;
}

.chipSizeSmall {
  height: 21px !important;
}

.MuiTableCell-paddingNone div button {
  margin: auto;
}

.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot,
.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
  padding-right: 22px !important;
}

.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  min-width: 0 !important;
}

span.MuiAutocomplete-tag {
  margin: 0 !important;
  font-size: 12px !important;
}

span.MuiTableSortLabel-root.MuiTableSortLabel-active {
  color: white
}

span.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon,
span.MuiTableSortLabel-root:hover {
  color: rgba(255, 255, 255, 0.54)
}

th.MuiTableCell-head::after,
th.sepColumna::after {
  content: '';
  background-color: rgba(255, 255, 255, 0.54);
  position: absolute;
  left: -1px;
  top: 8px;
  bottom: 8px;
  width: 1px;
}
